<template>
	<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
		<w-btn v-if="canSurvey" flat icon="far fa-bell" :loading="isLoading" mini @click="surveyNodes()">
			{{ $t('ecm.follow') }}
		</w-btn>
		<w-btn v-if="canUnsurvey" flat icon="far fa-bell-slash" :loading="isLoading" mini @click="unsurveyNodes()">
			{{ $t('ecm.unfollow') }}
		</w-btn>
	</v-flex>
	<v-flex v-else-if="listTileDisplay" shrink>
		<v-list-tile v-if="canSurvey" avatar @click="surveyNodes()">
			<v-list-tile-avatar>
				<v-progress-circular v-if="isLoading" color="primary" indeterminate />
				<v-icon v-else color="primary">far fa-bell</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'ecm.follow'" />
			</v-list-tile-content>
		</v-list-tile>
		<v-list-tile v-else-if="canUnsurvey" avatar @click="unsurveyNodes()">
			<v-list-tile-avatar>
				<v-progress-circular v-if="isLoading" color="primary" indeterminate />
				<v-icon v-else color="primary">far fa-bell-slash</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'ecm.unfollow'" />
			</v-list-tile-content>
		</v-list-tile>
	</v-flex>
</template>
<script>
import DocumentNodeSurveyModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentNodeSurveyModuleGuard'

export default {
	name: 'NodeSurveyButtons',
	mixins: [DocumentNodeSurveyModuleGuard],
	props: {
		value: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			isLoading: false
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		unsurveyedNodes: function () {
			return this.model.filter(node => !node.is_trash && !node.deleted_at && node.is_surveyed === false)
		},
		surveyedNodes: function () {
			return this.model.filter(node => !node.is_trash && !node.deleted_at && node.is_surveyed)
		},
		canSurvey: function () {
			return this.unsurveyedNodes.length > 0
		},
		canUnsurvey: function () {
			return this.surveyedNodes.length > 0
		}
	},
	watch: {
		value: {
			handler: function () {
				if (this.model.length > 0) {
					this.isLoading = true
					this.service.loadNodesSurveys(this.vendorId, this.model).finally(() => {
						this.isLoading = false
					})
				}
			}
		}
	},
	methods: {
		surveyNodes: function () {
			return this.service.createNodesSurveys(this.vendorId, this.unsurveyedNodes)
		},
		unsurveyNodes: function () {
			return this.service.deleteNodesSurveys(this.vendorId, this.surveyedNodes)
		}
	}
}
</script>
