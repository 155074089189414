import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'

export default {
	nodes: {
		surveys: {
			create: function (vendorId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId })
				return Backend.POST(URLS.api.vendors.nodes.surveys.uri, [vendorId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			read: NotImplementedYet,
			update: NotImplementedYet,
			delete: function (vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId })
				return Backend.DELETE(URLS.api.vendors.nodes.surveys.uri, [vendorId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			}
		}
	},
	documents: {
		surveys: {
			create: NotImplementedYet,
			read: function (vendorId, documentId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, documentId: documentId })
				return Backend.GET(URLS.api.vendors.documents.surveys.uri, [vendorId, documentId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		}
	},
	folders: {
		surveys: {
			create: NotImplementedYet,
			read: function (vendorId, folderId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, folderId: folderId })
				return Backend.GET(URLS.api.vendors.folders.surveys.uri, [vendorId, folderId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		}
	}
}
