// ============================================================================
// DocumentNodeSurveyService
// -------------------------
// DocumentNodeSurvey module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'

import API from '@/apis/DocumentNodeSurveyApi'

const Private = {
	requests: {
		nodes: {
			surveys: {
				create: function (vendorId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId })
					return API.nodes.surveys.create(vendorId, params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: function (vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId })
					return API.nodes.surveys.delete(vendorId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
				}
			}
		},
		documents: {
			surveys: {
				create: NotImplementedYet,
				read: function (vendorId, document, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, document: document })
					return API.documents.surveys.read(vendorId, document.id, params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		},
		folders: {
			surveys: {
				create: NotImplementedYet,
				read: function (vendorId, folder, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, folder: folder })
					return API.folders.surveys.read(vendorId, folder.id, params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	},
	service: {
		node: {
			survey: {
				create: NotImplementedYet,
				read: function (vendorId, node) {
					RequiredArguments({ vendorId: vendorId, node: node })
					let result = Promise.resolve(false)
					if (!node.is_trash && !node.deleted_at) {
						if (node.is_folder) {
							result = Private.requests.folders.surveys.read(vendorId, node)
						} else {
							result = Private.requests.documents.surveys.read(vendorId, node)
						}
					}
					return result
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		},
		nodes: {
			create: NotImplementedYet,
			read: NotImplementedYet,
			update: NotImplementedYet,
			delete: NotImplementedYet,
			surveys: {
				create: function (vendorId, nodes) {
					RequiredArguments({ vendorId: vendorId, nodes: nodes })
					const filters = Private.service.nodes.filters(nodes, false)
					return Private.requests.nodes.surveys.create(vendorId, null, filters).then(() => {
						nodes.forEach(node => {
							Private.store.node.survey.set(node, true, true)
						})
					})
				},
				read: function (vendorId, nodes) {
					RequiredArguments({ vendorId: vendorId, nodes: nodes })
					return Promise.all(nodes.filter(node => node?.hasOwnProperty('id')).map(node => Private.service.node.survey.read(vendorId, node)))
				},
				load: function (vendorId, nodes) {
					RequiredArguments({ vendorId: vendorId, nodes: nodes })
					return Promise.all(
						nodes
							.filter(node => node?.hasOwnProperty('id'))
							.map(node => {
								Private.service.node.survey.read(vendorId, node).then(isSurveyed => {
									Private.store.node.survey.set(node, isSurveyed)
									return isSurveyed
								})
							})
					)
				},
				update: NotImplementedYet,
				delete: function (vendorId, nodes) {
					RequiredArguments({ vendorId: vendorId, nodes: nodes })
					const filters = Private.service.nodes.filters(nodes, true)
					return Private.requests.nodes.surveys.delete(vendorId, filters).then(() => {
						nodes.forEach(node => {
							Private.store.node.survey.set(node, false, true)
						})
					})
				}
			},
			filters: function (nodes, isSurveyed) {
				RequiredArguments({ nodes: nodes, isSurveyed: isSurveyed })
				return nodes
					.filter(node => (isSurveyed ? node.is_surveyed : !node.is_surveyed))
					.map(node => (node.is_folder ? { folder_id: [node.id] } : { document_id: [node.id] }))
					.reduce((previous, current) => {
						if (current.folder_id && previous.folder_id) {
							previous.folder_id.push(...current.folder_id)
						} else if (current.document_id && previous.document_id) {
							previous.document_id.push(...current.document_id)
						} else {
							Object.assign(previous, current)
						}
						return previous
					}, {})
			}
		}
	},
	store: {
		node: {
			survey: {
				set: function (node, isSurveyed, recursive = false) {
					RequiredArguments({ node: node, isSurveyed: isSurveyed })
					return store.dispatch('documents/setNodeIsSurveyed', { nodeId: node.id, isSurveyed: isSurveyed }).then(() => {
						if (recursive) {
							if (Array.isArray(node.documents)) {
								node.documents.forEach(document => Private.store.node.survey.set(document, isSurveyed, true))
							}
							if (Array.isArray(node.folders)) {
								node.folders.forEach(folder => Private.store.node.survey.set(folder, isSurveyed, true))
							}
						}
					})
				}
			}
		}
	}
}

// -------
// Exports
// -------
export default {
	createNodeSurvey: Private.service.node.survey.create,
	readNodeSurvey: Private.service.node.survey.read,
	updateNodeSurvey: Private.service.node.survey.update,
	deleteNodeSurvey: Private.service.node.survey.delete,

	createNodesSurveys: Private.service.nodes.surveys.create,
	readNodesSurveys: Private.service.nodes.surveys.read,
	loadNodesSurveys: Private.service.nodes.surveys.load,
	updateNodesSurveys: Private.service.nodes.surveys.update,
	deleteNodesSurveys: Private.service.nodes.surveys.delete
}
